@use '../Global/' as *;

.p-top-mv {
  width: 100%;
  max-width: 1832px;
  padding-inline: 16px;
  margin-inline: auto;

  @include MQ(md) {
    padding-inline: 8px;
  }
}

.p-top-news {
  margin-top: max(-128px, -6.6666667vw);
  position: relative;
  width: 100%;
  max-width: 1004px;
  padding-inline: 25px;
  margin-inline: auto;

  @include MQ(md) {
    margin-top: -64px;
    padding-inline: 25px;
  }
}

.p-top-news__inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 24px;
  background-color: var(--white);
  padding: 24px 40px 0 40px;
  border-radius: 24px 24px 0 0;

  @include MQ(md) {
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 16px 0 16px;
  }
}

.p-top-news__main {
  width: calc(100% - 144px);
  flex: 1;

  @include MQ(md) {
    width: 100%;
  }
}

.p-top-news__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.p-top-news__lead {
  font-size: rem(20);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.45;
  color: var(--darkBlack);
}

.p-top-news__nav {
  display: flex;
  gap: 8px;

  @include MQ(md) {
    gap: 4px;
  }

  .c-label {
    width: 95px;
  }
}

.p-top-news__article {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > *+* {
    border-top: 1px solid var(--gray);
  }
}

.p-top-news__item {
  width: 100%;
  padding: 12px 16px;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

.p-top-news__link {
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  transition: opacity .3s ease-out;

  @include hover {
    opacity: .7;
  }
}

.p-top-news__date {
  order: 1;
}

.p-top-news__label {
  order: 2;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.p-top-news__title {
  order: 3;
  font-size: rem(16);
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-top-news__btn {
  flex-shrink: 1;
  font-size: rem(16);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.45;
  color: var(--darkBlack);
  padding-right: 40px;
  position: relative;
  transition: opacity .3s ease-out;

  @include hover {
    opacity: .7;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: 24px;
    background-image: url('../images/top/arrow-news.svg');
    background-size: contain;
    transform: translateY(-50%);
  }
}

.p-top-main {
  margin-top: 48px;
  padding-inline: 16px;

  @include MQ(md) {
    padding-inline: 8px;
  }
}

.p-top-main__inner {
  width: 100%;
  max-width: 1632px;
  padding-inline: 16px;
  padding-block: 40px;
  margin-inline: auto;
  background-image: var(--primary-gradient);
  border-radius: 24px;

  @include MQ(md) {
    padding-inline: 8px;
  }
}

.p-top-main__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @include MQ(md) {
    row-gap: 22px;
    padding-inline: 8px;
  }
}

.p-top-main__plenary {
  width: 100%;
  max-width: 890px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  padding: 21px 29px;
  border-radius: 13px;
  border: 2px solid var(--white);
  position: relative;
  z-index: 0;

  @include MQ(md) {
    width: calc(100% - 25px);
    padding: 21px 29px;
  }

  @include MQ(sm) {
    flex-direction: column;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: var(--white);
    border-radius: 8px;
  }
}

.p-top-main__plenary-heading {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 16px;

  img {
    width: 57px;

    @include MQ(md) {
      width: 40px;
    }
  }
}

.p-top-main__plenary-lead {
  font-size: rem(20);
  font-weight: 500;
  letter-spacing: 0;

  @include MQ(md) {
    font-size: rem(16);
  }
}

.p-top-main__plenary-link {
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  padding: 6px 52px 6px 0;
  position: relative;
  transition: opacity .3s ease-out;

  @include MQ(md) {
    font-size: rem(14);
    padding: 8px 52px 8px 0;
    margin-left: auto;
  }

  @include hover {
    opacity: .7;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 36px;
    height: 36px;
    background-image: url('../images/top/plenary-arrow.svg');
    background-size: contain;
    transform: translateY(-50%);
  }
}

.p-top-main__subcommittee {}

.p-top-main__subcommittee-lead {
  width: fit-content;
  margin-inline: auto;
  font-size: rem(20);
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0;
  color: var(--white);
  padding-inline: 24px;
  background: var(--secondary-gradient);
  position: relative;

  @include MQ(md) {
    font-size: rem(16);
  }
}

.p-top-main__subcommittee-list {
  margin-top: -10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  justify-content: center;
  gap: 24px;
  padding: 24px 38px;
  border: 2px solid var(--white);
  border-radius: 16px;

  @include MQ(md) {
    margin-top: -8px;
    grid-template-columns: repeat(auto-fill, 128px);
    gap: 22px;
    padding: 22px;
  }
}

.p-top-main__subcommittee-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border: 2px solid var(--white);
  border-radius: 50%;
  position: relative;
  z-index: 0;

  @include MQ(md) {
    width: 128px;
    height: 128px;
  }
}

.p-top-main__subcommittee-link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  transition: opacity .3s ease-out;

  @include MQ(md) {
    gap: 0;
  }

  @include hover {
    opacity: .7;
  }

  img {
    width: fit-content;

    @include MQ(md) {
      scale: .81825;
      transform-origin: top center;
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: var(--white);
    border-radius: 50%;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 4px;
    width: 36px;
    height: 36px;
    background-image: url('../images/top/subcommittee-arrow.svg');
    background-size: contain;

    @include MQ(md) {
      bottom: 1px;
      right: 9px;
      width: 24px;
      height: 24px;
    }
  }
}

.p-top-main__subcommittee-text {
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0;
  text-align: center;

  @include MQ(md) {
    font-size: rem(14);
    margin-top: -4px;
  }
}