@use '../Global/' as *;

.c-news {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  > *+* {
    border-top: 1px solid var(--gray);
  }
}

.c-news--top {
  margin-top: 16px;

  .c-news__item {
    padding: 12px 16px;

    @include MQ(md) {
      padding: 12px 8px;
    }
  }
}

.c-news__item {
  width: 100%;
  padding: 24px 16px;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

.c-news__link {
  display: flex;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  transition: opacity .3s ease-out;

  @include MQ(lg) {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 8px;
  }

  @include MQ(md) {
    column-gap: 12px;
    row-gap: 8px;
  }

  @include hover {
    opacity: .7;
  }
}

.c-news__date {
  order: 1;

  @include MQ(md) {
    font-size: rem(14);
  }
}

.c-news__label {
  order: 2;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.c-news__title {
  order: 3;
  font-size: rem(16);
  font-weight: 400;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include MQ(lg) {
    width: 100%;
  }

  @include MQ(md) {
    font-size: rem(14);
  }
}
