:root {
  --dark-black: #000000;
  --black: #2D2D2D;
  --gray: #BDBDBD;
  --white: #fbfbfb;
  --white02: #ffffff;
  --pink: #E76161;
  --sky: #0593CF;
  --green: #009886;
  --event: #51C55E;
  --dark-blue: #007D98;
  --cat: #00AB97;

  --primary-gradient: linear-gradient(104.51deg, #85DBE7 -13.75%, #83CFC6 58.06%, #92D891 98.46%);
  --secondary-gradient: linear-gradient(90deg, #84D2CE 0%, #83D0C7 100%);
}
