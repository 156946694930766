@use '../Global/' as *;

.p-news-category {

    &__inner {
        display: flex;
        justify-content: space-between;
        margin-top: 104px;
        padding: 0 40px;

        @include MQ('md') {
            flex-direction: column-reverse;
            margin-top: 64px;
            padding: 0;
        }
    }

    &__main {
        width: 60%;
        margin-right: 54px;

        @include MQ('md') {
            margin-right: 0;
            width: 100%;
        }
    }

    &__article {
        margin-bottom: 64px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__article--heading {
        border-bottom: 1px solid var(--gray);
        padding-bottom: 8px;
    }

    &__article--title {
        font-size: rem(20);
        font-weight: bold;
    }

    &__article--label {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-top: 16px;
    }

    &__article--date {
        font-size: 14px;
    }

    &__article--list {
        display: flex;
        flex-wrap: wrap;
    }

    &__article--item {
        margin-right: 16px;
    }

    &__article--images {
        margin: 24px 0 0;

        img {
            max-width: 350px;
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }

    &__event--images {
        margin: 24px 0 0;

        img {
            width: 80%;
            max-width: 500px;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }

    &__button {
        margin-top: 24px;
    }

    &__button--link {
        font-size: rem(16);
        font-weight: bold;
        background-color: var(--pink);
        padding: 12px 24px;
        color: var(--white02);
        border-radius: 8px;
        display: block;
        max-width: 224px;
        margin: 0 auto;
        position: relative;

        &:hover {
            opacity: .7;
            transition: opacity 0.3s ease-out;
        }
    }
    
    &__button--link::after {
        content: '';
        display: inline-block;
        background: url("../images/banner/cta-arrow.svg") no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
    }

    &__article--text {
        margin-top: 24px;
        & > * {
            margin-top: 16px;
        }
        a {
            text-decoration: underline;
        }
    }

    &__side {
        width: 40%;
        max-width: 267px;

        @include MQ('md') {
            width: 100%;
            max-width: unset;
            margin-bottom: 64px;
        }
    }

    &__side--item {
        margin-bottom: 12px;
    }

    &__side--button {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__side--button--link {
        font-size: rem(16);
        border: 3px solid var(--green);
        border-radius: 8px;
        padding: 12px 16px;
        display: block;
        text-align: center;
        position: relative;
    }

    &__side--button--link::after {
        content: '';
        display: inline-block;
        background: url("../images/news/news_link_icon.svg") no-repeat;
        background-size: contain;
        width: 19px;
        height: 19px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;

        @include MQ('md') {
            right: 64px;
        }
    }

    &__side--button-inner {
        margin-left: -19px;
    }

    &__side--heading {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray);
        padding-bottom: 12px;

        @include MQ('md') {
            justify-content: flex-start;
        }
    }

    &__side--title {
        font-size: rem(14);
        font-weight: normal;
        margin-top: 4px;
    }

    &__side--date {
        @include MQ('md') {
            margin-right: 8px;
        }
    }
}