@use '../Global/' as *;

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: var(--white);
  padding-block: 11px;
  width: 100%;

  @include MQ(md) {
    padding-block: 14px;
    height: 65px;
  }
}

.l-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-header__logo {
  display: flex;
  width: 284px;

  @include MQ(md) {
    width: 250px;
  }
}

.l-header__navigation {

  @include MQ(md) {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 152, 134, .7);
    position: fixed;
    right: -100%;
    top: 0;
    overflow-y: auto;
    padding-block: 126px 40px;
    transition: right .3s ease-out;
  }
}

.l-header__list {
  display: flex;
  gap: 24px;

  @include MQ(md) {
    flex-direction: column;
    gap: 32px;
    width: max-content;
    margin-inline: auto;
  }
}

.l-header__item {
  position: relative;
}

.l-header__link {
  color: var(--darkBlack);
  font-size: rem(16);
  font-weight: 500;
  line-height: 1;
  padding-block: 9px;
  position: relative;
  transition: color .3s ease-out;

  @include MQ(md) {
    display: flex;
    color: var(--white);
    padding-block: 0;
  }

  @include hover {
    color: var(--green);
  }
}

.l-header__link--subcommittee {

  @include MQ(md) {
    font-weight: 400;
    cursor: text;
  }
}

.l-header__link--arrow {
  padding-right: 28px;

  &::after {
    content: '';
    position: absolute;
    top: 18px;
    right: 8px;
    width: 12px;
    height: 8px;
    background-image: url('../images/header/menu-arrow.svg');
    background-size: contain;
    transition: transform .3s ease-out;

    @include MQ(md) {
      display: none;
    }
  }
}

.l-header__child {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: max-content;
  padding: 24px 16px;
  background-color: var(--white);
  transition: opacity .3s ease-out, 
              visibility .3s ease-out;

  &--subcommittee {
    @include MQ(xxl) {
      left: unset;
      right: 0;
      transform: unset;
    }
  }

  @include MQ(md) {
    position: static;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 32px 0;
    background-color: unset;
    transform: unset;
  }
}

.l-header__child-link {
  font-size: rem(16);
  font-weight: 500;
  color: var(--black);
  padding-left: 12px;
  position: relative;
  transition: color .3s ease-out;

  @include MQ(md) {
    padding-left: 0;
    font-weight: 700;
    color: var(--white);
  }

  @include hover {
    color: var(--green);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background-color: var(--green);
    border-radius: 50%;

    @include MQ(md) {
      display: none;
    }
  }
}

[data-menu="close"] {
  .l-header__child {
    opacity: 0;
    visibility: hidden;

    @include MQ(md) {
      opacity: 1;
      visibility: visible;
    }
  }
}

[data-menu="open"] {

  .l-header__link {

    &::after {
      transform: rotate(180deg);
    }
  }

  .l-header__child {
    opacity: 1;
    visibility: visible;
  }
}

.l-header__hambButton {
  display: none;

  @include MQ(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: relative;
    z-index: 10;
  }
}

.l-header__hambButton-line {
  display: block;
  width: 28px;
  height: 2px;
  background-color: var(--green);
  transition: background-color .3s ease-out, transform .3s ease-out;
}

.l-header__hambButton-label {
  margin-top: 2px;
  font-size: rem(12);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  color: var(--green);
  transition: color .3s ease-out;
}


[data-menu-state="close"] {
  
}

[data-menu-state="open"] {
  overflow: hidden;

  .l-header__navigation {
    right: 0;
  }

  .l-header__hambButton-line {
    background-color: var(--white);

    &:nth-child(1) {
      transform: translateY(0) rotate(45deg);
    }

    &:nth-child(2) {
      display: none;
    }

    &:nth-child(3) {
      transform: translateY(-6px) rotate(-45deg);
    }
  }

  .l-header__hambButton-label {
    color: var(--white);
  }
}