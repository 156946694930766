@use '../Global/' as *;

:root {
  --label-borderColor: var(--black);
  --label-bgColor: var(--white);
  --label-fontColor: var(--black);
}

.--news {
  --label-borderColor: var(--sky);
  --label-bgColor: var(--sky);
  --label-fontColor: var(--white);
}

.--event {
  --label-borderColor: var(--event);
  --label-bgColor: var(--event);
  --label-fontColor: var(--white);
}

.--plenary-session {
  --label-borderColor: var(--dark-blue);
  --label-bgColor: var(--dark-blue);
  --label-fontColor: var(--white);
}

.--subcommittee {
  --label-borderColor: var(--cat);
  --label-bgColor: var(--cat);
  --label-fontColor: var(--white);
}

.c-label {
  width: 100px;
  height: 24px;
}

.c-label__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50vh;
  font-size: rem(12);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  color: var(--label-fontColor);
  border-width: 1px;
  border-style: solid;
  border-color: var(--label-borderColor);
  background-color:  var(--label-bgColor);
  border-radius: 50vh;
}

.c-label--small {

  .c-label__link {
    font-size: rem(10);
  }
}