@use '../Global/' as *;

.c-heading {
  padding-block: 24px;
  background-image: var(--primary-gradient);

  @include MQ(md) {
    padding-inline: 8px;
  }
}

.c-heading__inner {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 24px;
  background-color: var(--white);
  padding: 24px 40px 16px;
  border-radius: 16px;

  @include MQ(md) {
    padding: 12px 24px 8px;
  }
  
  img {
    width: fit-content;

    @include MQ(md) {
      width: 30px;
    }
  }
}

.c-heading__main {
  font-size: rem(32);
  font-weight: 500;
  letter-spacing: 0;

  @include MQ(md) {
    font-size: rem(24);
  }
}