@use '../Global/' as *;

.l-footer {
  @include font-default;

  background-color: var(--green);
  padding: 64px 0 40px 0;
  margin-top: 64px;

  @include MQ('md') {
    padding: 64px 0 108px 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @include MQ('md') {
      display: block;
    }
  }

  &__logo--images {
    max-width: 304px;
    display: block;
    margin: 0 auto;
  }

  &__cta {
    margin-top: 16px;
  }

  &__cta--text {
    color: var(--white);
    text-align: center;
  }

  &__cta--button {
    margin-top: 8px;
  }

  &__cta--link {
    color: var(--green);
    background-color: var(--white);
    font-weight: bold;
    padding: 12px 24px;
    position: relative;
    border-radius: 8px;
    display: block;
    max-width: 284px;
    margin: 0 auto;
    text-align: center;

    &:hover {
      opacity: .7;
      transition: opacity 0.3s ease-out;
    }
  }

  &__cta--link::after {
    content: '';
    display: inline-block;
    background: url('../images/footer/footer_arrow.svg') no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 74px;
    width: 24px;
    height: 24px;
  }

  &__cta--link-inner {
    margin-left: -20px;
  }

  &__copy {
    margin-top: 60px;
  }

  &__copy-pc {
    @include MQ('md') {
      display: none;
    }
  }

  &__copy-sp {
    display: none;

    @include MQ('md') {
      display: block;
      margin-top: 40px;
    }
  }

  &__copy--link {
    font-size: rem(14);
    color: var(--white);

    @include MQ('md') {
      display: block;
      width: fit-content;
      margin: 0 auto;
      text-align: center;
    }

    &:hover {
      opacity: .7;
      transition: opacity 0.3s ease-out;
    }
  }

  &__copy--link-inner {
    position: relative;
  }

  &__copy--link-inner::after {
    content: '';
    display: inline-block;
    background: url('../images/footer/link_icon.svg') no-repeat;
    background-size: contain;
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
  }

  &__copy--text {
    text-align: center;
    color: var(--white);
    font-size: rem(12);
  }

  &__nav {
    width: 62%;
    margin-left: 32px;

    @include MQ('lg') {
      width: 54%;
    }
    @include MQ('md') {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
    }

    @include MQ('sm') {
      text-align: center;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -24px;

    @include MQ('sm') {
      display: inline-block;
      text-align: left;
    }
  }

  &__item {
    padding-left: 24px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: var(--white);
    font-weight: bold;

    &:hover {
      opacity: .7;
      transition: opacity 0.3s ease-out;
    }
  }

  &__child--link {
    color: var(--white);
    font-weight: bold;

    &:hover {
      opacity: .7;
      transition: opacity 0.3s ease-out;
    }
  }

  &__child--list {
    margin-top: 24px;
    margin-left: 1.5em;
  }

  &__child--item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.l-footer__policy {
  display: block;
  width: fit-content;
  margin-inline: auto;
  margin-top: 8px;
  font-size: rem(14);
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: 0;
  color: var(--white);
  padding-right: 14px;
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    background: url('../images/footer/privacy_arrow.svg') no-repeat;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 12px;
  }
}