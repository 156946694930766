@use '../Global/' as *;

.l-banner {
}

.l-banner__portal {
  position: fixed;
  top: 81px;
  right: 132px;
  z-index: 8888;
  max-width: 190px;
  transition: opacity .3s ease-out;

  @include MQ(xxl) {
    right: 12px;
  }

  @include MQ(md) {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 64px;
  }

  @include hover {
    opacity: .7;
  }
}

.l-banner__contact {
  position: fixed;
  right: 76px;
  bottom: 16px;
  z-index: 8888;

  @include MQ(xxl) {
    right: 12px;
  }

  @include MQ(md) {
    width: 100%;
    right: 0;
    bottom: 0;
  }
}

.l-banner__contact-content {
  position: relative;
}

.l-banner__contact-button {
  position: absolute;
  top: -36px;
  right: 0;
  display: block;
  width: 28px;
  height: 28px;
  background-color: var(--green);
  background-image: url('../images/banner/contact-close.svg');
  background-position: center;
  border: 2px solid var(--white);
  border-radius: 50%;
  transition: top .3s ease-out,
              width .3s ease-out,
              height .3s ease-out,
              background-image .3s ease-out;

  @include MQ(md) {
    top: -32px;
    right: 8px;
  }
}

.l-banner__contact-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  background-color: var(--white);
  border: 2px solid var(--green);
  border-radius: 24px;
  padding: 38px;
  transition: opacity .3s ease-out,
              visibility .3s ease-out;

  @include MQ(md) {
    flex-direction: row;
    column-gap: 12px;
    border-bottom: unset;
    border-radius: 16px 16px 0 0;
    padding: 12px 12px 8px;
  }
}

.l-banner__contact-text {
  font-size: rem(16);
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--darkBlack);

  @include MQ(md) {
    font-size: rem(15);
    line-height: 1.7;
  }
}

.l-banner__contact-link {
  font-size: rem(16);
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0;
  color: var(--white);
  background-color: var(--pink);
  border-radius: 8px;
  padding: 12px 72px 12px 24px;
  transition: opacity .3s ease-out;
  position: relative;

  @include MQ(md) {
    padding: 12px 58px 12px 18px;
  }

  @include hover {
    opacity: .7;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url('../images/banner/cta-arrow.svg');
    background-size: contain;

    @include MQ(md) {
      right: 18px;
    }
  }
}

[data-state="close"] {
  &.l-banner__contact {
    z-index: 0;
  }

  .l-banner__contact-button {
    top: 146px;
    width: 38px;
    height: 38px;
    background-image: url('../images/banner/contact-open.svg');

    @include MQ(md) {
      top: 26px;
    }
  }

  .l-banner__contact-inner {
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: -1;
  }
}