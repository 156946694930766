@use '../Global/' as *;

.p-contact__list {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  max-width: 812px;
  padding-inline: 16px;
  margin-top: 64px;
  margin-inline: auto;
}

.p-contact__item {
  width: 100%;
  padding: 15px 24px;
  border: 1px solid var(--gray);
  border-radius: 16px;

  @include MQ(md) {
    padding: 16px 29px;
  }
}

.p-contact__item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;

  @include MQ(md) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  }
}

.p-contact__main {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  row-gap: 4px;
}

.p-contact__title {
  font-size: rem(16);
  font-weight: 700;
  letter-spacing: 0;
}

.p-contact__info {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.p-contact__tag {
  --tag-color: var(--sky);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 24px;
  font-size: rem(12);
  font-weight: 700;
  letter-spacing: 0;
  border-width: 1px;
  border-style: solid;
  border-color: var(--tag-color);
  border-radius: 50vh;
  color: var(--tag-color);
}

.--event {
  --tag-color: var(--event);
}
.--seminar {
  --tag-color: var(--sky);
}

.p-contact__date {
  font-size: rem(14);
  letter-spacing: 0;
}

.p-contact__cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;

  @include MQ(md) {
    flex-direction: row-reverse;
    column-gap: 24px;
    margin-left: auto;
  }
}

.p-contact__box-link {
  font-size: rem(20);
  font-weight: 700;
  letter-spacing: 0;
  color: var(--white);
  background-color: var(--green);
  padding: 4px 56px 4px 16px;
  border-radius: 8px;
  position: relative;
  transition: opacity .3s ease-out;

  @include hover {
    opacity: .7;
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: url('../images/contact/arrow-white.svg') no-repeat;
    background-size: contain;
  }
}

.p-contact__text-link {
  font-size: rem(14);
  letter-spacing: 0;
  color: var(--darkBlack);
  padding-right: 36px;
  position: relative;
  transition: opacity .3s ease-out;

  @include hover {
    opacity: .7;
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url('../images/contact/arrow-green.svg') no-repeat;
    background-size: contain;
  }
}