@use '../Global/' as *;

.p-privacy {

    &__inner {
        max-width: 960px;
        margin: 0 auto;
    }

    &__item {
        margin-top: 64px;

        @include MQ('md') {
            margin-top: 40px;
        }
    }

    &__item--title {
        font-size: rem(20);
        font-weight: bold;
        border-bottom: 1px solid var(--gray);
        margin-bottom: 16px;
        padding-bottom: 8px;
    }

    &__item--text {
        font-size: rem(14);
    }

    &__list {
        margin-top: 16px;
    }

    &__list--burette {
        margin-left: -1.5em;
        margin-right: .5em;
    }

    &__list--item {
        padding-left: 2em;
        font-size: rem(14);
    }

    &__information {
        margin-top: 16px;
    }

    &__information--title {
        font-size: rem(16);
        font-weight: bold;
    }

    &__information--text {
        font-size: rem(14);
    }

    &__day {
        margin-top: 64px;

        @include MQ('md') {
            margin-top: 40px;
        }
    }

    &__day--text {
        font-size: rem(14);
        text-align: right;
    }
}