@use '../Global/' as *;

.p-news {
  max-width: 793px;
  margin-top: 64px;
}

.p-news__label {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.p-news__list {
  margin-top: 40px;
}

.p-news__pagenation {
  margin-top: 64px;

  .nav-links {
    position: relative;
    min-height: 36px;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-numbers {
    padding: 0 12px;
    transition: opacity .3s ease-out;

    @include hover {
      opacity: .7;
    }
  }

  .prev,
  .next {
    flex: 1;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
    }
  }

  .prev {
    left: -100px;

    @include MQ(md) {
      left: -91px;
    }
  }
  .next {
    right: -100px;

    @include MQ(md) {
      right: -91px;
    }
  }
}